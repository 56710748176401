import useScrollAnimation from '@apps/www/src/www/hooks/useScrollAnimation';
import SVImage from '@pkgs/shared-client/components/SVImage';
import { useRef } from 'react';

const SVHorizontalParallaxGallery = ({ sources }: { sources: string[] }) => {
	const containerRef = useRef<HTMLDivElement>(null);
	const row1Ref = useRef<HTMLDivElement>(null);
	const row2Ref = useRef<HTMLDivElement>(null);

	useScrollAnimation({
		containerRef,
		update: (progress: number, { windowHeight }) => {
			const movement = Math.min(150, (windowHeight / 900) * 150);

			if (row1Ref.current && row2Ref.current) {
				// 0 to 1 to -1 to 1
				const progressBoth = progress * 2 - 1;

				row1Ref.current.style.transform = `translateX(${progressBoth * -1 * movement}px)`;
				row2Ref.current.style.transform = `translateX(${progressBoth * movement}px)`;
			}
		},
	});

	return (
		<div className="mt-12 w-full overflow-hidden" ref={containerRef}>
			<div className="relative mb-[1.8%]" ref={row1Ref}>
				<picture>
					<source srcSet={sources[0]} media="(min-width: 1024px)" />
					<SVImage src={sources[0]} className="mt-10 w-full" />
				</picture>
			</div>
			<div className="relative" ref={row2Ref}>
				<picture>
					<source srcSet={sources[1]} media="(min-width: 1024px)" />
					<SVImage src={sources[1]} />
				</picture>
			</div>
		</div>
	);
};

export default SVHorizontalParallaxGallery;
